const environment = process.env.NODE_ENV;

const apis = {
  BASE_LOCAL_URL: environment === "development" ? "http://localhost:3001" : "",
  BASE: environment === "development" ? "http://localhost:5000" : "",
  LOGIN: "https://compute7api.asynctech.in/api/v1/login",
  GET_USER_DETAILS: "https://compute7api.asynctech.in/api/v1/user/details",
  STUDENT_REGISTER:
    "https://compute7api.asynctech.in/api/v1/public/register",
  GET_ALL_SUBJECT:
    "https://compute7api.asynctech.in/api/v1/user/getAllSubjects",
  ADD_QUESTION: "https://compute7api.asynctech.in/api/v1/user/addQuestion",
  SEARCH_QUESTION:
    "https://compute7api.asynctech.in/api/v1/user/searchQuestion",
  CHANGE_QUESTION_STATUS:
    "https://compute7api.asynctech.in/api/v1/user/changeQuestionStatus",
  GET_QUESTION_BY_ID:
    "https://compute7api.asynctech.in/api/v1/user/getQuestionAnswer",
  GET_ANSWER: "https://compute7api.asynctech.in/api/v1/user/getAnswer",
  UPDATE_QUESTION:
    "https://compute7api.asynctech.in/api/v1/user/updateQuestion",
  CREATE_QUESTION:
    "https://compute7api.asynctech.in/api/v1/user/createTest",
  GET_ALL_TEST: "https://compute7api.asynctech.in/api/v1/user/getAllTest",
  GET_ALL_TEST_STUDNET:
    "https://compute7api.asynctech.in/api/v1/user/getAllTestStudent",
  STUDENT_TEST_REGISTER:
    "https://compute7api.asynctech.in/api/v1/user/testRegistration",
  GET_UPCOMING_TESTS_STUDENT:
    "https://compute7api.asynctech.in/api/v1/user/getUpcomingTests",
  GET_TEST_DETAILS_BY_ID:
    "https://compute7api.asynctech.in/api/v1/user/getTestById",
  START_TEST: "https://compute7api.asynctech.in/api/v1/user/startTest",
  GET_QUESTIONS_TAKETEST:
    "https://compute7api.asynctech.in/api/v1/user/getQuenStarttime",
  SAVE_ANSWER: "https://compute7api.asynctech.in/api/v1/user/saveAnswer",
  END_TEST: "https://compute7api.asynctech.in/api/v1/user/endTest",
  GET_ALL_COMPLETED_TEST_STUDENT:
    "https://compute7api.asynctech.in/api/v1/user/getAllCompletedTest",
  GET_TEST_RESULT_STUDENT:
    "https://compute7api.asynctech.in/api/v1/user/getResultMainDetailsByTestId",
  GET_RESULT_QUESTIONS_STUDENTS:
    "https://compute7api.asynctech.in/api/v1/user/getQuestionAnswerByIds",
};

export default apis;
